import React, { useState, ChangeEvent, FormEvent } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import bg1 from '../assets/contactus_background/0001.png';
import { axiosInstance } from '../services/axios'; // Import Axios

const Contact = () => {
    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        email: '',
        subject: '',
        message: '',
    });

    // Event handler for form submission
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        
        try {
            // Send a POST request to the backend with the form data
            const response = await axiosInstance.post('/contact', formData);

            // Handle the response, e.g., show a success message to the user
            console.log('Email sent:', response.data);
            alert('Email sent successfully');
            
            // Reset the form
            setFormData({
                name: '',
                mobileNumber: '',
                email: '',
                subject: '',
                message: '',
            });
        } catch (error) {
            // Handle errors, e.g., show an error message to the user
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    // Event handler to update form data as the user types
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div
            id="contact"
            className="max-w-[1440px] w-full m-auto py-16 px-5 relative"
        >
            <div
                style={{ backgroundImage: `url(${bg1})` }}
                className="w-full h-[84vh] md:h-[85vh] bg-center bg-cover"
            >
                <div className="flex flex-col justify-center items-center w-full h-full bg-white bg-opacity-90 text-blue-700">
                    <div className="flex w-full h-1/6 justify-center items-center">
                        <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold font-["Outfit"]'>
                            Let's Talk!
                        </h1>
                    </div>
                    <div className="flex flex-col lg:flex-row w-full h-5/6">
                        <div className="flex lg:hidden w-full h-1/6 justify-center px-12">
                            <div className="flex w-full h-full flex-row justify-between items-center">
                                <a
                                    href="mailto:bingsulab68@gmail.com"
                                    className="flex"
                                >
                                    <div className="flex flex-row w-full items-center ">
                                        <GrMail size={35} className="mx-4" />
                                    </div>
                                </a>
                                <a
                                    href="https://www.instagram.com/bingsulabdessert/"
                                    target="_blank"
                                    className="flex"
                                >
                                    <div className="flex flex-row w-full items-center ">
                                        <FaInstagram
                                            size={35}
                                            className="mx-4"
                                        />
                                    </div>
                                </a>
                                <a
                                    href="https://www.facebook.com/bingsulabdessertcafe"
                                    target="_blank"
                                    className="flex"
                                >
                                    <div className="flex flex-row w-full items-center ">
                                        <FaFacebookF
                                            size={35}
                                            className="mx-4"
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="flex w-full lg:w-7/12 h-5/6 lg:h-full lg:py-12 lg:px-8 justify-center">
                            <form className="flex w-full h-full" onSubmit={handleSubmit}>
                                <div className="flex-col flex w-full items-center">
                                    <input
                                        className="border m-2 py-2 px-5 w-10/12 rounded-lg shadow"
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    <input
                                        className="border m-2 py-2 px-5 w-10/12 rounded-lg shadow"
                                        type="tel"
                                        placeholder="Mobile Number"
                                        name="mobileNumber"
                                        value={formData.mobileNumber}
                                        onChange={handleChange}
                                    />
                                    <input
                                        className="border m-2 py-2 px-5 w-10/12 rounded-lg shadow"
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <input
                                        className="border m-2 py-2 px-5 w-10/12 rounded-lg shadow"
                                        type="text"
                                        placeholder="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                    <textarea
                                        className="border m-2 py-2 px-5 w-10/12 rounded-lg shadow"
                                        rows={5}
                                        style={{ resize: 'none' }}
                                        placeholder="Your Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                    <div className="flex w-40 h-12 mx-2 my-6 bg-blue-700 rounded-2xl cursor-pointer">
                                        <button className="w-full">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="hidden lg:flex w-1/12 h-full justify-center">
                            <div className="w-1 h-full bg-blue-700" />
                        </div>
                        <div className="hidden lg:flex w-4/12 h-full flex-col py-10 px-4">
                            <a
                                href="mailto:bingsulab68@gmail.com"
                                className="flex my-8"
                            >
                                <div className="flex flex-row w-full items-center ">
                                    <GrMail size={40} className="mx-4" />
                                    <h2 className='text-lg font-bold font-["Rubik"]'>
                                        bingsulab68@gmail.com
                                    </h2>
                                </div>
                            </a>
                            <a
                                href="https://www.instagram.com/bingsulabdessert/"
                                target="_blank"
                                className="flex my-8"
                            >
                                <div className="flex flex-row w-full items-center ">
                                    <FaInstagram size={40} className="mx-4" />
                                    <h2 className='text-lg font-bold font-["Rubik"]'>
                                        @bingsulabdessert
                                    </h2>
                                </div>
                            </a>
                            <a
                                href="https://www.facebook.com/bingsulabdessertcafe"
                                target="_blank"
                                className="flex my-8"
                            >
                                <div className="flex flex-row w-full items-center ">
                                    <FaFacebookF size={40} className="mx-4" />
                                    <h2 className='text-lg font-bold font-["Rubik"]'>
                                        Bingsu Lab Dessert Café
                                    </h2>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
