import React, { useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import bg1 from '../assets/aboutus_background/0001.png';
import bingsu1 from '../assets/aboutus_bingsu/0001.png';
import bingsu2 from '../assets/aboutus_bingsu/0002.png';
import bingsu3 from '../assets/aboutus_bingsu/0003.png';
import bingsu4 from '../assets/aboutus_bingsu/0004.png';
import bingsu5 from '../assets/aboutus_bingsu/0005.png';
import bingsu6 from '../assets/aboutus_bingsu/0006.png';
import bingsu7 from '../assets/aboutus_bingsu/0007.png';
import bingsu8 from '../assets/aboutus_bingsu/0008.png';

const bingsuSlides = [
    bingsu1,
    bingsu2,
    bingsu3,
    bingsu4,
    bingsu5,
    bingsu6,
    bingsu7,
    bingsu8,
];

const About = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const goToPreviousSlide = () => {
        setCurrentSlide(
            (prevSlide) =>
                (prevSlide - 1 + bingsuSlides.length) % bingsuSlides.length,
        );
    };

    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % bingsuSlides.length);
    };

    useEffect(() => {
        const interval = setInterval(goToNextSlide, 1500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="max-w-[1440px] w-full m-auto py-16 px-5 relative">
            <div
                style={{ backgroundImage: `url(${bg1})` }}
                className="w-full h-[84vh] md:h-[85vh] bg-center bg-cover"
            >
                <div className="flex flex-col justify-center items-center w-full h-full px-2 md:px-16 lg:px-20 bg-white bg-opacity-90 text-blue-700">
                    <div className="flex w-full h-1/6 justify-center items-center">
                        <h1 className='text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold font-["Outfit"]'>
                            About Bingsu Lab
                        </h1>
                    </div>
                    <div className="flex w-full h-1/6 justify-center">
                        <p className='text-justify text-xs sm:text-sm md:text-md xl:text-lg font-bold font-["Roboto"] leading-relaxed py-2'>
                            Bingsu Lab - Where Friends Became Founders
                            <br />
                            <br />
                            Inspired by a shared love for Korean bingsu and the
                            relentless heat, a group of friends founded Bingsu
                            Lab. Our blue and white theme mirrors the refreshing
                            world of bingsu, and we invite you to join us on
                            this frozen adventure.
                        </p>
                    </div>
                    <div className="flex w-full h-4/6 flex-col md:flex-row py-4 justify-around items-center">
                        <div className="hidden sm:flex aspect-square w-36 h-36 sm:w-52 sm:h-52 md:w-72 md:h-72 lg:w-96 lg:h-96 justify-center items-center">
                            <div
                                style={{
                                    backgroundImage: `url(${bingsuSlides[currentSlide]})`,
                                }}
                                className="w-full h-full rounded-3xl bg-center bg-cover border-4 border-blue-700 px-4 duration-1000 ease-in-out relative"
                            >
                                <div className="absolute inset-0 hidden md:flex items-center justify-around group">
                                    <div
                                        onClick={goToPreviousSlide}
                                        className="hidden group-hover:block text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
                                    >
                                        <BsChevronCompactLeft size={30} />
                                    </div>
                                    <div
                                        onClick={goToNextSlide}
                                        className="hidden group-hover:block text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
                                    >
                                        <BsChevronCompactRight size={30} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full md:w-7/12 items-center pt-4 md:pl-6">
                            <p className='text-justify text-xs sm:text-sm md:text-md xl:text-lg font-bold font-["Roboto"] leading-relaxed'>
                                In December 2021, we opened the very first
                                Bingsu Lab in Kuantan, embarking on a journey to
                                bring the taste of Korean bingsu to our
                                community.
                                <br />
                                <br />
                                The overwhelming support and love we received
                                led us to expand our dream. In October 2022, we
                                proudly launched our second branch in Indera
                                Mahkota Kuantan, allowing even more dessert
                                enthusiasts to savor the delightful flavors we
                                create.
                                <br />
                                <br />
                                At Bingsu Lab, we're not just serving bingsu;
                                we're crafting experiences that keep you cool in
                                the Malaysian heat. Come and chill with us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
