import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import bg1 from '../assets/specialty_background/0001.png';
import bingsu1 from '../assets/specialty_bingsu/0001.png';
import bingsu2 from '../assets/specialty_bingsu/0002.png';
import bingsu3 from '../assets/specialty_bingsu/0003.png';
import bingsu4 from '../assets/specialty_bingsu/0004.png';
import bingsu5 from '../assets/specialty_bingsu/0005.png';
import bingsu6 from '../assets/specialty_bingsu/0006.png';
import bingsu7 from '../assets/specialty_bingsu/0007.png';
import bingsu8 from '../assets/specialty_bingsu/0008.png';
import beverage1 from '../assets/specialty_beverage/0001.png';
import beverage2 from '../assets/specialty_beverage/0002.png';
import beverage3 from '../assets/specialty_beverage/0003.png';
import beverage4 from '../assets/specialty_beverage/0004.png';
import beverage5 from '../assets/specialty_beverage/0005.png';
import beverage6 from '../assets/specialty_beverage/0006.png';
import beverage7 from '../assets/specialty_beverage/0007.png';
import beverage8 from '../assets/specialty_beverage/0008.png';

const bingsuSlides = [
    bingsu1,
    bingsu2,
    bingsu3,
    bingsu4,
    bingsu5,
    bingsu6,
    bingsu7,
    bingsu8,
];

const beverageSlides = [
    beverage1,
    beverage2,
    beverage3,
    beverage4,
    beverage5,
    beverage6,
    beverage7,
    beverage8,
];

const combineSlides = [
    bingsu1,
    beverage1,
    bingsu2,
    beverage2,
    bingsu3,
    beverage3,
    bingsu4,
    beverage4,
    bingsu5,
    beverage5,
    bingsu6,
    beverage6,
    bingsu7,
    beverage7,
    bingsu8,
    beverage8,
];

const Specialty = () => {
    const [currentSlide1, setCurrentSlide1] = useState(0);
    const [currentSlide2, setCurrentSlide2] = useState(0);
    const [currentSlide3, setCurrentSlide3] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide1(
                (prevSlide) => (prevSlide + 1) % bingsuSlides.length,
            );
        }, 1500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide2(
                (prevSlide) => (prevSlide + 1) % beverageSlides.length,
            );
        }, 1500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide3(
                (prevSlide) => (prevSlide + 1) % combineSlides.length,
            );
        }, 1500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="max-w-[1440px] w-full m-auto py-16 px-5 relative">
            <div
                style={{ backgroundImage: `url(${bg1})` }}
                className="w-full h-[84vh] md:h-[85vh] bg-center bg-cover"
            >
                <div className="flex flex-col justify-center items-center w-full h-full px-2 md:px-16 lg:px-20 bg-white bg-opacity-90 text-blue-700">
                    <div className="flex w-full h-1/6 justify-center items-center">
                        <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold font-["Outfit"]'>
                            Specialty
                        </h1>
                    </div>
                    <div className="hidden md:flex flex-row w-full h-4/6 justify-evenly items-center">
                        <div className="flex aspect-auto w-80 h-96 justify-center items-center px-14">
                            <div
                                style={{
                                    backgroundImage: `url(${bingsuSlides[currentSlide1]})`,
                                }}
                                className="w-full h-full rounded-full bg-center bg-cover border-4 border-blue-700 px-4 py-8 duration-1000 ease-in-out relative"
                            >
                                <h1 className='hidden lg:flex w-full h-8 text-blue-700 text-3xl font-normal font-["Rubik"] justify-center'>
                                    BINGSU
                                </h1>
                            </div>
                        </div>
                        <div className="flex aspect-auto w-80 h-96 justify-center items-center px-14">
                            <div
                                style={{
                                    backgroundImage: `url(${beverageSlides[currentSlide2]})`,
                                }}
                                className="w-full h-full rounded-full bg-center bg-cover border-4 border-blue-700 px-4 py-8 duration-1000 ease-in-out relative"
                            >
                                <h1 className='hidden lg:flex w-full h-8 text-blue-700 text-3xl font-normal font-["Rubik"] justify-center'>
                                    BEVERAGE
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex md:hidden flex-row w-full h-4/6 justify-center items-center">
                        <div className="flex aspect-auto w-60 h-96 justify-center items-center">
                            <div
                                style={{
                                    backgroundImage: `url(${combineSlides[currentSlide3]})`,
                                }}
                                className="w-full h-full rounded-full bg-center bg-cover border-4 border-blue-700 duration-1000 ease-in-out relative"
                            ></div>
                        </div>
                    </div>
                    <div className="flex w-full h-1/6 justify-center items-center">
                        <Link to="/menu" className="flex w-40 h-12 bg-blue-700 rounded-2xl transition-transform transform hover:scale-105">
                            <button className="w-full">See More</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Specialty;
