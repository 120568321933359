import React from 'react';
import Home from './components/Home';
import About from './components/About';
import Specialty from './components/Specialty';
import Contact from './components/Contact';
import Location from './components/Location';

function App() {
    return (
        <div className="App">
            <div className="mx-auto"> {/* This container will hold scrollable content */}
                <Home />
                <About />
                <Specialty />
                <Contact />
                <Location />
            </div>
        </div>
    );
}

export default App;
