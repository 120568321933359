import React, { useState, useEffect } from 'react';
import bg1 from '../assets/home_background/0001.png';
import bg2 from '../assets/home_background/0002.png';
import bg3 from '../assets/home_background/0003.png';
import bingsu1 from '../assets/home_bingsu/0001.png';
import bingsu2 from '../assets/home_bingsu/0002.png';
import bingsu3 from '../assets/home_bingsu/0003.png';
import bingsu4 from '../assets/home_bingsu/0004.png';
import bingsu5 from '../assets/home_bingsu/0005.png';
import bingsu6 from '../assets/home_bingsu/0006.png';
import bingsu7 from '../assets/home_bingsu/0007.png';
import bingsu8 from '../assets/home_bingsu/0008.png';

const bgSlides = [bg1, bg2, bg3];
const bingsuSlides = [
    bingsu1,
    bingsu2,
    bingsu3,
    bingsu4,
    bingsu5,
    bingsu6,
    bingsu7,
    bingsu8,
];

const Home = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSlide2, setCurrentSlide2] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % bgSlides.length);
        }, 1500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval2 = setInterval(() => {
            setCurrentSlide2(
                (prevSlide) => (prevSlide + 1) % bingsuSlides.length,
            );
        }, 1500);

        return () => clearInterval(interval2);
    }, []);

    return (
        <div className="max-w-[1440px] w-full m-auto pt-20 pb-16 px-4 bg-white relative">
            <div
                style={{ backgroundImage: `url(${bgSlides[currentSlide]})` }}
                className="w-full h-[60vh] md:h-[75vh] bg-center bg-cover duration-1000 ease-in-out"
            >
                <div className="flex flex-col md:flex-row justify-center items-center w-full h-full bg-white bg-opacity-90">
                    <div className="hidden md:flex w-1/2 h-full flex-col justify-center px-9 lg:px-16 xl:px-24 2xl:px-28">
                        <h1 className='text-5xl lg:text-6xl xl:text-7xl font-bold font-["Outfit"] text-blue-700'>
                            Welcome to
                            <br />
                            Bingsu Lab
                            <br />
                            Dessert Cafe
                        </h1>
                        <div className='text-lg lg:text-xl font-bold font-["Roboto"] pt-6 text-blue-700'>
                            Chill Out with Bingsu Bliss!
                        </div>
                    </div>

                    <div className="flex md:w-1/2 h-full justify-center items-center">
                        <div
                            style={{
                                backgroundImage: `url(${bingsuSlides[currentSlide2]})`,
                            }}
                            className="w-72 sm:w-96 h-72 sm:h-96 rounded-tl-full rounded-bl-full rounded-br-full bg-center bg-cover border-4 duration-1000 ease-in-out border-blue-700"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
