import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="max-w-[1440px] w-full m-auto px-5 relative">
            <div className='w-full h-[50vh] md:h-52 flex flex-col text-blue-900 font-["Roboto"] bg-violet-100 bg-opacity-60'>
                
                <div className="hidden md:flex w-full h-1 bg-blue-700 bg-opacity-50" />
                <div className='w-full md:h-40 flex flex-col md:flex-row justify-center py-4 text-black font-["Roboto"]'>
                    <div className='w-full md:w-1/3 h-full flex flex-col items-center'>
                        <text className="text-md my-1">Our Story</text>
                        <Link className="text-sm my-1" to="/about">About Us</Link>
                        <Link className="text-sm my-1" to="/menu">Specialty</Link>
                        <Link className="text-sm my-1" to="/location">Location</Link>
                    </div>
                    <div className='w-full md:w-1/3 h-full flex flex-col items-center'>
                        <text className="text-md my-1">Social media</text>
                        <a
                            href="https://www.facebook.com/bingsulabdessertcafe"
                            target="_blank"
                        >
                            <text className="text-sm my-1">Facebook</text>
                        </a>
                        <a
                            href="https://www.instagram.com/bingsulabdessert/"
                            target="_blank"
                        >
                            <text className="text-sm my-1">Instagram</text>
                        </a>
                    </div>
                    <div className='w-full md:w-1/3 h-full flex flex-col items-center'>
                        <text className="text-md my-1">Contact Us</text>
                        <text className="text-sm my-1">Email / bingsulab68@gmail.com</text>
                    </div>
                    
                </div>
                <div className="hidden md:flex w-full h-1 bg-blue-700 bg-opacity-20" />
                <div className='w-full md:h-40 flex flex-col md:flex-row justify-center py-4 font-["Roboto"]'>
                    <div className='hidden md:flex w-1/3 h-full items-center'>
                    </div>
                    <div className='w-full md:w-1/3 h-full flex items-center justify-center text-black'>
                        <text className="text-xs lg:text-md">Copyright 2023 © Bingsu Lab Dessert Cafe</text>
                    </div>
                    <div className='w-full md:w-1/3 h-full flex items-center justify-center md:justify-end pl-2 text-stone-500'>
                        <text className="text-xs lg:text-sm">Website Developed By Colmer.Developer</text>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Footer;
