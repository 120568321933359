import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Navbar from './components/Navbar';
import About from './components/About';
import Specialty from './components/Specialty';
import Contact from './components/Contact';
import Location from './components/Location';
import Menu from './components/Menu';
import Footer from './components/Footer';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <div className="fixed top-0 left-0 w-full z-[1000]">
                <Navbar />
            </div>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/about" element={<About />} />
                <Route path="/specialty" element={<Specialty />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/location" element={<Location />} />
                <Route path="/menu" element={<Menu />} />
            </Routes>
            <div className=" bottom-0 left-0 w-full">
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default AppRouter;
