import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import logo from '../assets/logo/0001.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    };

    return (
        <div className='w-full min-h-[80px] flex justify-center items-center absolute z-10 text-blue-900 font-["Roboto"] bg-white bg-opacity-90'>

            <div className='hidden md:flex w-full h-full items-center justify-center'>
                <div className='w-3/12 h-full items-center justify-center'>
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-9/12 aspect-auto object-cover ml-4"
                        />
                    </Link>
                </div>
                <div className='w-6/12 h-full items-center justify-center mt-2'>
                    <ul className="hidden md:flex w-full h-full item-center justify-around">
                        <li className="text-lg lg:text-xl">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="text-lg lg:text-xl">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="text-lg lg:text-xl">
                            <Link to="/menu">Specialty</Link>
                        </li>
                        <li className="text-lg lg:text-xl">
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li className="text-lg lg:text-xl">
                            <Link to="/location">Location</Link>
                        </li>
                    </ul>
                </div>
                <div className='w-3/12 h-full items-center'>
                </div>  
            </div>


            
            {/* Hamburger Icon */}
            <div className="flex md:hidden w-full h-full items-center justify-center">
                <div className='flex flex-row w-full h-full items-center justify-center'>
                    <div className='w-1/6 h-full flex items-center justify-center' />
                    <div className='w-4/6 h-full flex items-center justify-center'>
                        <Link to="/">
                            <img
                                src={logo}
                                alt="Logo"
                                className="w-72 aspect-auto object-cover"
                            />
                        </Link>
                    </div>
                    <div className='w-1/6 h-full flex justify-center items-center'>
                        <div onClick={handleNav} className='flex w-full h-full justify-center items-center'>
                            <FaBars size={30} className="cursor-pointer" />
                        </div>
                    </div>  
                </div>
                {/* Mobile Menu */}
                <div
                    onClick={handleNav}
                    className={
                        nav
                            ? 'overflow-y-hidden ease-in duration-300 absolute text-blue-900 left-0 top-0 w-full h-screen bg-white bg-opacity-80 px-4 py-7 flex flex-col'
                            : 'absolute top-0 h-screen left-[-100%] ease-in duration-500'
                    }
                >
                    <ul className="h-full w-full text-center pt-12">
                        <li className="text-2xl py-8">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="text-2xl py-8">
                            <Link to="/about">About Us</Link>
                        </li>
                        <li className="text-2xl py-8">
                            <Link to="/menu">Specialty</Link>
                        </li>
                        <li className="text-2xl py-8">
                            <Link to="/contact">Contact Us</Link>
                        </li>
                        <li className="text-2xl py-8">
                            <Link to="/location">Location</Link>
                        </li>
                    </ul>
                </div>
            </div>  
        </div>
    );
};

export default Navbar;
