import React from 'react';
import bg1 from '../assets/location_background/0001.png';
import location1 from '../assets/location_shop/0001.png';
import location2 from '../assets/location_shop/0002.png';

const location = () => {
    return (
        <div className="max-w-[1440px] w-full m-auto pt-16 px-5 relative">
            <div
                style={{ backgroundImage: `url(${bg1})` }}
                className="w-full h-[84vh] md:h-[85vh] bg-center bg-cover"
            >
                <div className="flex flex-col justify-center items-center w-full h-full bg-white bg-opacity-90 text-blue-700">
                    <div className="flex w-full h-1/6 justify-center items-center">
                        <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold font-["Outfit"]'>
                            Location
                        </h1>
                    </div>
                    <div className="flex flex-col md:flex-row w-full h-5/6">
                        <div className="hidden md:flex flex-col w-1/2 h-full items-center">
                            <div
                                style={{ backgroundImage: `url(${location1})` }}
                                className="w-80 h-56 bg-center bg-cover rounded-3xl border-4 border-blue-700 my-4"
                            />
                            <h2 className="text-2xl font-bold font-['Roboto']">Bingsu Lab Dessert Cafe</h2>
                            <h2 className="text-2xl font-bold font-['Roboto']">Lorong Tun Ismail</h2>
                            <h2 className="text-center text-lg my-4">
                                30, Lorong Tun Ismail 8,
                                <br/>
                                Sri Dagangan Bussiness Centre,
                                <br/>
                                25200 Kuantan, Pahang
                            </h2>
                            <h2 className="text-lg">Opening hour: 12PM - 11PM</h2>
                        </div>
                        <div className="hidden md:flex flex-col w-1/2 h-full items-center">
                            <div
                                style={{ backgroundImage: `url(${location2})` }}
                                className="w-80 h-56 bg-center bg-cover rounded-3xl border-4 border-blue-700 my-4"
                            />
                            <h2 className="text-2xl font-bold font-['Roboto']">Bingsu Lab Dessert Cafe</h2>
                            <h2 className="text-2xl font-bold font-['Roboto']">Indera Mahkota</h2>
                            <h2 className="text-center text-lg my-4">
                                Ground Floor, No. B50, Lorong, Jln IM 7/1
                                <br/>
                                Bandar Indera Mahkota,
                                <br/>
                                25200 Kuantan, Pahang
                            </h2>
                            <h2 className="text-lg">Opening hour: 1PM - 11PM</h2>
                        </div>

                        <div className="flex md:hidden flex-col w-full h-full items-center">
                            <div
                                style={{ backgroundImage: `url(${location1})` }}
                                className="w-48 sm:w-56 h-36 sm:h-44 bg-center bg-cover rounded-3xl border-4 border-blue-700"
                            />
                            <div className="text-center">
                                <h2 className="text-md font-bold font-['Roboto']">Bingsu Lab Dessert Cafe</h2>
                                <h2 className="text-md font-bold font-['Roboto']">Lorong Tun Ismail</h2>
                                <h2 className="text-sm">Opening hour: 12PM - 11PM</h2>
                            </div>
                        </div>
                        <div className="flex md:hidden flex-col w-full h-full items-center">
                            <div
                                style={{ backgroundImage: `url(${location2})` }}
                                className="w-48 sm:w-56 h-36 sm:h-44 bg-center bg-cover rounded-3xl border-4 border-blue-700"
                            />
                            <div className="text-center">
                                <h2 className="text-md sm:text-md font-bold font-['Roboto']">Bingsu Lab Dessert Cafe</h2>
                                <h2 className="text-md sm:text-md font-bold font-['Roboto']">Indera Mahkota</h2>
                                <h2 className="text-xs sm:text-sm">Opening hour: 1PM - 11PM</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default location;
