import React from 'react';
import menu1 from '../assets/menu/0001.png';
import menu2 from '../assets/menu/0002.png';
import menu3 from '../assets/menu/0003.png';
import menu4 from '../assets/menu/0004.png';

const About = () => {

    return (
        <div
            className="max-w-[1440px] w-full m-auto py-16 px-5 relative"
        >
            <img
                src={menu1}
                alt="Bingsu Menu"
                className="w-full h-auto max-w-full"
            />
            <img
                src={menu2}
                alt="Beverage Menu"
                className="w-full h-auto max-w-full"
            />
            <img
                src={menu3}
                alt="Food Menu"
                className="w-full h-auto max-w-full"
            />
            <img
                src={menu4}
                alt="Mix & Match Menu"
                className="w-full h-auto max-w-full"
            />
        </div>
    );
};

export default About;
